import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box } from "@mui/material";
import { FunctionComponent, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

type Props = {};

const LoginWaitScreen: FunctionComponent<Props> = ({}: Props) => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const doLogin = async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.handleRedirectPromise();
      await instance.loginRedirect();
    }
  };
  useEffect(() => {
    void doLogin();
  }, [inProgress]);

  return isAuthenticated ? (
    <Navigate to="/" />
  ) : (
    <Box
      height={"100vh"}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    ></Box>
  );
};

export default LoginWaitScreen;
