import { FunctionComponent, useEffect, useState } from 'react'
import '../App.css'
import { PowerBIEmbed } from 'powerbi-client-react'
import {
  Embed,
  IReportEmbedConfiguration,
  models,
  Report
} from 'powerbi-client'
import { getToken } from '../service'

import { refreshTime } from '../config'
import 'powerbi-report-authoring'
import { useNavigate } from 'react-router-dom'

import useMediaQuery from '@mui/material/useMediaQuery'
import { CustomTheme } from '../theme'
import { useMsal } from '@azure/msal-react'
import { Box, CircularProgress, Typography } from '@mui/material'

interface IAuthorisedPowerBiReportProps {}

const AuthorisedPowerBiReport: FunctionComponent<
  IAuthorisedPowerBiReportProps
> = ({}) => {
  const [report, setReport] = useState<Report>()
  const isOnMobile = useMediaQuery(CustomTheme.breakpoints.down('sm'), {
    noSsr: true
  })

  const navigate = useNavigate()
  const [embedConfig, setEmbedConfig] = useState<IReportEmbedConfiguration>()
  const [isAuthorised, setIsAuthorised] = useState<boolean>(false)
  const [reportId] = useState<string>('')
  const { accounts, instance } = useMsal()
  instance.getActiveAccount()
  const orgName = window.location.hostname.split('.')[0]

  // Authorisation
  useEffect(() => {
    const account = accounts[0]

    const roles: string[] = account
      ? ((account.idTokenClaims as any)['roles'] as unknown as [])
      : []
    if (roles && roles.length > 0) {
      setIsAuthorised(roles.includes(orgName) || roles.includes('admin'))
    }
  }, [])

  useEffect(() => {
    const doGetEmbedInfo = async () => {
      const [token, embedUrl] = await getToken(orgName)
      if (token) {
        setEmbedConfig({
          type: 'report',
          accessToken: token,
          tokenType: models.TokenType.Embed,
          embedUrl,
          pageName: window.location.pathname.split('/')[2],
          settings: {
            panes: {
              filters: { visible: false },
              pageNavigation: { visible: false }
            },
            layoutType: isOnMobile
              ? models.LayoutType.MobilePortrait
              : models.LayoutType.Master
          }
        })
      }
    }
    void doGetEmbedInfo()
  }, [reportId])

  useEffect(() => {
    if (report) {
      const intervalId = setInterval(async () => {
        if (report) {
          const [token] = await getToken(orgName)
          await report.setAccessToken(token)
          console.log('Token refreshed')
        }
      }, refreshTime)
      return () => clearInterval(intervalId)
    }
  }, [report])

  useEffect(() => {
    if (report) {
      report.on('pageChanged', async () => {
        const activePage = await report.getActivePage()
        if (activePage.name !== window.location.pathname.split('/')[2]) {
          navigate(`${activePage.displayName}/${activePage.name}`)
          console.log('Updated url trigged by page changing')
        }
      })
    }
  }, [report])
  return (
    <Box height={1} width={1}>
      {isAuthorised ? (
        embedConfig ? (
          <PowerBIEmbed
            embedConfig={embedConfig}
            getEmbeddedComponent={(embedObject: Embed) => {
              setReport(embedObject as Report)
            }}
            cssClassName="Report"
          ></PowerBIEmbed>
        ) : (
          <Box
            display="flex"
            flexDirection={'column'}
            width={1}
            height={1}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CircularProgress />

            <Box mb={1} />
            <Typography variant="h5">
              Due to the large amount of data being analysed Commpact can take
              up to 30 seconds to load.
            </Typography>
            <Typography variant="h5">Thanks for your patience.</Typography>
          </Box>
        )
      ) : (
        <Box display={'flex'} alignItems="baseline">
          <Typography>
            Your not authorised to view this report. Please{' '}
            <a href="#" onClick={async () => await instance.logoutRedirect()}>
              log out and try a different account
            </a>{' '}
            or contact your admin
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default AuthorisedPowerBiReport
