import { FunctionComponent } from 'react'
import '../App.css'
import Box from '@mui/material/Box'
import { Typography, useMediaQuery } from '@mui/material'

import 'powerbi-report-authoring'
import commpactLogo from '../static/image/commpact_logo.svg'
import { CustomTheme } from '../theme'
import { Outlet } from 'react-router-dom'
import { Link } from 'react-router-dom'

interface ILayoutProps {}
const Layout: FunctionComponent<ILayoutProps> = () => {
  const isOnMobile = useMediaQuery(CustomTheme.breakpoints.down('sm'), {
    noSsr: true
  })

  const orgName = window.location.hostname.split('.')[0]
  const formatOrgName = (text: string) => {
    text = text.replaceAll('-', ' ')
    return text.toUpperCase()
  }

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      //   alignItems={'st'}
    >
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        sx={{ backgroundColor: 'common.white' }}
        width="100%"
      >
        <Box mr={3} />

        <Box
          width={isOnMobile ? '50%' : '15%'}
          height="100%"
          onClick={() => {
            window.location.replace('/')
          }}
          sx={{ order: 1, cursor: 'pointer' }}
        >
          <img
            alt="Commpact Logo"
            src={commpactLogo}
            height="100%"
            width={'100%'}
          />
        </Box>
        <Box
          display="flex"
          width={isOnMobile ? '100%' : '25%'}
          sx={{ order: isOnMobile ? 5 : 2 }}
        ></Box>
        <Box flexGrow={1} sx={{ order: 3 }} />
      </Box>
      <Box display="flex" width="100%" height={'90vh'}>
        <Box mr={3} />
        <Outlet />
      </Box>
      <Box display="flex" alignItems="center" width="100%" height="8vh">
        <Box mr={3} />
        <Typography variant="h6">
          {`© ${new Date().getFullYear()} Commpact`}
        </Typography>
      </Box>
    </Box>
  )
}

export default Layout
