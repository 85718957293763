import { groupId, baseUrl, hostKey } from "./config";

const getToken = async (orgName: string): Promise<[string, string]> => {
  const response = await fetch(
    `${baseUrl}/GetTokenByOrgName?code=${hostKey}&orgName=${orgName}&groupId=${groupId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
  const object = JSON.parse(await response.text());
  return [object["embedToken"], object["embedUrl"]];
};

export { getToken };
